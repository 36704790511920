import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Center, SegmentedControl, Box, Autocomplete } from '@mantine/core';
import { memo, useEffect } from 'react';
import { BsBarChartLineFill, BsFillGridFill } from 'react-icons/bs';
import { MdFavorite } from 'react-icons/md';
import { proxy, useSnapshot } from 'valtio';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { AiFillHeart, AiOutlineHeart, AiOutlineSearch, AiOutlineUnorderedList, } from 'react-icons/ai';
import TradingPlatformSymbolChart from './QuoteBodyBaseChart';
import TradingPlatformSymbol from './QuoteBodyBase';
import { fill, fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '~/pages/heineken_template/_private/store';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSignalr2QuoteSort } from '../useSignalr2QuoteSort';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { removeAfterWhitespace } from '../removeAfterWhitespace';
import { useTimeout, useUpdateEffect } from 'react-use';
import LoadCard from '../LoadCard';
export const tradingPlatfromStore = proxy({
    symbolListState: 'default',
    listDisplayType: 'string',
});
const segmentedConfig = [
    { icon: <BsBarChartLineFill />, text: '熱門商品' },
    { icon: <MdFavorite />, text: '觀察清單' },
];
/** 搭配 `一個預設商品群`+`一個自選群` */
const SymbolPlatformBase = memo(function SymbolPlatformBase(props) {
    //theme
    const theme = useThemeStore(s => s.theme);
    //hook
    const listState = useSnapshot(tradingPlatfromStore).symbolListState;
    const listTypeState = useSnapshot(tradingPlatfromStore).listDisplayType;
    /** 搜尋欄商品預設 或 餵進去的商品列 */
    const searchListType = props.searchListType ?? 'default';
    /** 調整切換按鈕的 icon & displayText */
    const segmented = props.segmentedConfig ?? segmentedConfig;
    /** 是否要顯示可以切換顯示商品類型的按鈕 */
    const listTypeSwitch = props.listTypeSwitch ?? true;
    //自選股state、acts
    const { state: watchListState, acts: watchListActs } = useSymbolWatchList(props.watchListGroup);
    const watchlistSymbol = watchListState.symbolListArray;
    const defaultSymbol = props.symbol;
    //當前商品
    const symbol = useSnapshot(store.charting).symbol;
    const symbolData = listState === 'default' ? defaultSymbol : watchlistSymbol;
    //商品列有變動重新訂閱
    useEffect(() => {
        signalrStore2.addQuote(symbolData);
        return () => {
            signalrStore2.removeQuote(symbolData);
        };
    }, [JSON.stringify(defaultSymbol), JSON.stringify(watchlistSymbol), JSON.stringify(symbolData)]);
    //圖表商品有變動重新訂閱
    useEffect(() => {
        signalrStore2.addQuote(symbol);
        return () => {
            signalrStore2.removeQuote(symbol);
        };
    }, [symbol]);
    //排序
    const sortData = useSignalr2QuoteSort(symbolData) ?? [];
    const value = signalrHooks2.useQuotes(sortData);
    //單一商品報價
    //const symbolValue = useSnapshot(signalrStore2.values.quote)[symbol]
    //商品名稱
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const searchDefaultSymbols = Object.entries(dictionary).map(s => ({
        symbol: s[0],
        name: s[1],
    }));
    const searchCustomizedSymbols = Object.entries(symbolData).map(s => ({
        symbol: s[1],
        name: dictionary[s[1] ?? ''],
    }));
    const searchList = searchListType === 'default' ? searchDefaultSymbols : searchCustomizedSymbols;
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme}>
        <classes.header className={theme}>
          <HeaderContent watchListState={watchListState} watchListActs={watchListActs}/>
        </classes.header>
        <classes.search className={theme}>
          <Autocomplete icon={<AiOutlineSearch size={22}/>} placeholder='搜尋商品代號' data={searchList.map(s => s.symbol + ' ' + s.name)} variant='unstyled' limit={100} maxDropdownHeight={200} onItemSubmit={item => {
            const symbolNumber = removeAfterWhitespace(item.value);
            store.charting.changeSymbol(symbolNumber);
        }} css={css `
              width: 100%;
            `}/>
          {listTypeSwitch === true && (<classes.layout className={theme} onClick={() => {
                tradingPlatfromStore.listDisplayType =
                    listTypeState === 'string' ? 'chart' : 'string';
            }}>
              {listTypeState === 'string' ? (<BsFillGridFill size={16} fill={'#cacaca'}/>) : (<AiOutlineUnorderedList size={16} fill={'#cacaca'}/>)}
            </classes.layout>)}
        </classes.search>

        <classes.slider className={theme}>
          <SegmentedControl className={theme} css={css `
              width: 100%;
            `} onChange={val => (tradingPlatfromStore.symbolListState =
            val)} data={[
            {
                label: (<Center>
                    {segmented[0].icon}
                    <Box ml={10}>{segmented[0].text}</Box>
                  </Center>),
                value: 'default',
            },
            {
                label: (<Center className={theme}>
                    {segmented[1].icon}
                    <Box ml={10}>{segmented[1].text}</Box>
                  </Center>),
                value: 'watchList',
            },
        ]}/>
        </classes.slider>
        {listTypeState === 'string' && <TradingPlatformSymbol.Header />}
        {listTypeState === 'chart' && <TradingPlatformSymbolChart.Header />}
        <classes.body>
          <SymbolListContent quoteArray={value} watchListSymbol={watchListState.symbolListArray} watchlistActs={watchListActs}/>
        </classes.body>
      </classes.container>
    </classes.Root>);
});
/** 頂部 顯示目前商品代號與名稱 加入自選股 */
const HeaderContent = memo(function HeaderContent(props) {
    /** 目前圖表商品 */
    const symbol = useSnapshot(store).charting.symbol;
    const hadSymbol = props.watchListState.symbolListArray?.includes(symbol);
    return (<div css={fill_horizontal_all_center}>
      <div css={css `
          ${fill_horizontal_all_center};
          width: 30px;
          cursor: pointer;
        `} onClick={event => {
            if (hadSymbol) {
                props.watchListActs.removeSymbol(symbol);
            }
            if (!hadSymbol) {
                props.watchListActs.addSymbol(symbol, 20);
            }
        }}>
        {hadSymbol ? (<AiFillHeart size={16} fill={'#6d9af0'}/>) : (<AiOutlineHeart size={16} fill={'#6d9af0'}/>)}
      </div>
      <span>{symbol}</span>
      <SymbolName symbol={symbol}/>
    </div>);
});
/** 商品列 */
const SymbolListContent = memo(function SymbolListContent(props) {
    const listTypeState = useSnapshot(tradingPlatfromStore).listDisplayType;
    /** 載入緩衝時間 */
    const [initializeReady, reset] = useTimeout(1250);
    useUpdateEffect(() => {
        reset();
    }, [initializeReady]);
    return (<div css={css `
        ${fill_vertical_cross_center};
        gap: 4px;
        padding: 2px;
      `}>
      {props.quoteArray.length === 0 && initializeReady() ? (<LoadCard.emtpty />) : (props.quoteArray.length === 0 && <LoadCard.Loading />)}
      {listTypeState === 'string' &&
            props.quoteArray.map((s, index) => {
                return (<TradingPlatformSymbol.Body key={index} quote={s} watchListSymbol={props.watchListSymbol} watchlistActs={props.watchlistActs}/>);
            })}
      {listTypeState === 'chart' &&
            props.quoteArray.map((s, index) => {
                return (<TradingPlatformSymbolChart.Body key={index} quote={s} watchListSymbol={props.watchListSymbol} watchlistActs={props.watchlistActs}/>);
            })}
    </div>);
});
const scrollbar2Css = css `
  ${fill_vertical_cross_center};
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #555;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 5px;
    background-color: #ccc;
  }
`;
const classes = {
    Root: styled.div `
    ${fill};
  `,
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
    border-radius: 12px;
  `,
    header: styled.div `
    ${fill_vertical_all_center};
    height: 60px;
    border-radius: 12px 12px 0px 0px;
  `,
    search: styled.div `
    ${fill_horizontal_all_center};
    width: 86%;
    height: 48px;
  `,
    slider: styled.div `
    ${fill_horizontal_all_center};
    width: 86%;
    height: 72px;
    padding: 8px 0px;
  `,
    layout: styled.div `
    ${fill_horizontal_all_center};
    width: 26px;
    height: 26px;
    border-radius: 4px;
    cursor: pointer;
  `,
    body: styled.div `
    ${fill_vertical_all_center};
    border-radius: 0px 0px 12px 12px;
    height: calc(100% - 200px);
    ${scrollbar2Css};
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    &.dark {
      background-color: #181920;
    }
    &.light {
      background-color: #fefefe;
    }
  }
  ${classes.header} {
    &.dark {
      background-color: #202026;
    }
    &.light {
      background-color: #eaeaea;
    }
  }
  ${classes.search} {
    &.dark {
      border-bottom: 1px solid #666666;
    }
    &.light {
      border-bottom: 1px solid #666666;
    }
  }
  ${classes.layout} {
    &.dark {
      background-color: #3c3e43;
      &:hover {
        background-color: #4c4e53;
      }
    }
    &.light {
      background-color: #eaeaea;
      &:hover {
        background-color: #dadada;
      }
    }
  }
  ${classes.slider} {
    .mantine-SegmentedControl-root {
      &.dark {
        background-color: #141517;
        .mantine-SegmentedControl-active {
          background-color: #3c3e43;
        }
        .mantine-SegmentedControl-label {
          color: #dadada;
        }
        .mantine-SegmentedControl-labelActive {
          color: #fafafa;
        }
      }
      &.light {
        background-color: #e0e0e0;
        .mantine-SegmentedControl-active {
          background-color: #fdfdfd;
        }
        .mantine-SegmentedControl-label {
          color: #6f6f6f;
        }
        .mantine-SegmentedControl-labelActive {
          color: #252525;
        }
      }
    }
  }
  ${classes.body} {
  }
`;
export default {
    Display: SymbolPlatformBase,
    classes,
};
