import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody';
import { useSnapshot } from 'valtio';
import { VscError } from 'react-icons/vsc';
import { tradingPlatfromStore } from './SymbolPlatformBase';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { store } from '~/pages/heineken_template/_private/store';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
const QuoteBodyBase = memo(function QuoteBodyBase(props) {
    const theme = useThemeStore(s => s.theme);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    //報價類
    const quote = props.quote;
    const change = processQuoteToReadable(props.quote)?.closeChange;
    const charting = useSnapshot(store.charting);
    const currentSymbol = charting.symbol;
    //選擇中
    const isActive = quote?.symbol === currentSymbol ? 'isActive' : '';
    //自選
    const hadSymbol = props.watchListSymbol?.includes(quote.symbol);
    //已在自選清單內所顯示的icon
    const symbolListState = useSnapshot(tradingPlatfromStore).symbolListState;
    const selectSymbol = symbolListState === 'watchList' ? (<VscError size={16} fill={classesFill.iconRemoveFill}/>) : (<AiFillHeart size={16} fill={classesFill.iconDefaultFill}/>);
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme + isActive} onClick={() => {
            store.charting.changeSymbol(quote?.symbol);
        }}>
        <classes.icon onClick={event => {
            /** 阻止加入商品至自選股時執行父元素的切換商品事件 */
            event.stopPropagation();
            if (hadSymbol) {
                props.watchlistActs.removeSymbol(quote?.symbol);
            }
            if (!hadSymbol) {
                props.watchlistActs.addSymbol(quote?.symbol, 20);
            }
        }}>
          {hadSymbol ? (selectSymbol) : (<AiOutlineHeart size={16} fill={classesFill.iconDefaultFill + 'aa'} css={css `
                z-index: 1300;
              `}/>)}
        </classes.icon>
        <p css={css `
            justify-content: start !important;
            white-space: nowrap;
            overflow: clip;
            font-size: 13px;
            text-overflow: ellipsis;
            &:hover {
              animation: ${marquee} 3s linear infinite;
              overflow: visible;
            }
          `}>
          {quote?.symbol}
          {dictionary[quote?.symbol ?? '']?.replace('指數', '')?.replace('兌美元', '')}
        </p>
        <classes.item>
          <QuoteValueItem.ohlc.Display quote={quote} ohlcType='close'/>
        </classes.item>
        <classes.item>
          <QuoteValueItem.change.Display quote={quote} changeType='closeChange'/>
        </classes.item>
        <classes.item>
          <QuoteValueItem.change.Display quote={quote} changeType='closeChangePercent' unit='%'/>
        </classes.item>
      </classes.container>
    </classes.Root>);
});
const QuoteBodyBaseHeader = memo(function QuoteBodyBaseHeader() {
    const theme = useThemeStore(s => s.theme);
    const iconFill = theme === 'dark' ? '#ffffff' : '#111111';
    const sortSnap = useSnapshot(useSortSignalrDataStore); //useSortSignalrDataStore 遷移
    const toggleOrderKey = sortSnap.orderKey === 'desc' ? 'asc' : 'desc';
    const getArrow = useCallback((sortKey) => {
        return sortKey === sortSnap.sortKey ? (toggleOrderKey === 'desc' ? '▲' : '▼') : undefined;
    }, [sortSnap.sortKey, toggleOrderKey]);
    const handleSortSelected = useCallback((sortKey) => {
        useSortSignalrDataStore.sortKey = sortKey;
        useSortSignalrDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<classes.Root>
      <classes.container css={css `
          width: 98%;
          border-radius: 0px;
          border-bottom: 1px solid #666666;
        `}>
        <p onClick={() => {
            useSortSignalrDataStore.sortKey = 'none';
            useSortSignalrDataStore.orderKey = 'none';
        }}>
          <AiOutlineHeart size={16} fill={iconFill} css={css `
              z-index: 1300;
            `}/>
        </p>
        <p onClick={() => {
            handleSortSelected('symbol');
        }}>
          商品{getArrow('symbol')}
        </p>
        <p onClick={() => {
            handleSortSelected('close');
        }}>
          價格{getArrow('close')}
        </p>
        <p onClick={() => {
            handleSortSelected('change');
        }}>
          漲跌{getArrow('change')}
        </p>
        <p onClick={() => {
            handleSortSelected('changePrecent');
        }}>
          幅度{getArrow('changePrecent')}
        </p>
      </classes.container>
    </classes.Root>);
});
const classesFill = {
    iconRemoveFill: '#992323',
    iconDefaultFill: '#6d9af0',
};
const classes = {
    Root: styled.div `
    ${fill_vertical_all_center};
    height: 32px;
    width: 100%;
    flex-shrink: 0;
  `,
    container: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 10% 32% 20% 20% 18%;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    user-select: none;
    & > p {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      margin: 0;
    }
  `,
    item: styled.p `
    display: flex;
    flex-direction: row;
    justify-content: end !important;
    margin-right: 8px !important;
    line-height: 16px;
    margin: 0;
  `,
    icon: styled.p `
    cursor: pointer;
    position: relative;

    &:hover {
      & > * {
        height: 20px;
        width: 20px;
        transition: 0.3s;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        opacity: 0;
        transition: all 0.5s;
        box-shadow: 0 0 6px 10px ${classesFill.iconDefaultFill}cc;
      }

      &:active:after {
        box-shadow: 0 0 0 0 ${classesFill.iconDefaultFill}cc;
        position: absolute;
        border-radius: 50%;
        opacity: 1;
        transition: 0s;
      }

      &:active {
        top: 1px;
      }
    }
  `,
};
export default {
    Body: QuoteBodyBase,
    Header: QuoteBodyBaseHeader,
    classes,
    classesFill,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    &.dark {
      color: #fafafa;
      &:hover {
        background-color: #282930;
      }
    }
    &.light {
      color: #252525;
      &:hover {
        background-color: #eaeaea;
      }
    }
    &.darkisActive {
      color: #fafafa;
      background-color: #28292faa;
      &:hover {
        background-color: #28292f;
      }
    }
    &.lightisActive {
      color: #252525;
      background-color: #eaeaeaaa;
      &:hover {
        background-color: #eaeaea;
      }
    }
  }
`;
const marquee = keyframes `
0% { transform: translateX(0); }
100% { transform: translateX(-30%); }
`;
