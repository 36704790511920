import { css } from '@emotion/react';
import React, { memo, useMemo } from 'react';
import { ResponsiveContainer, YAxis, XAxis, AreaChart, Area } from 'recharts-new';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { useSymbolHistoryResource } from '~/modules/screener/containers/useSymbolHistoryResource';
import dayAPI from '~/utils/dayAPI';
const subtractBusinessUnits = (date, unitsToSubtract, interval) => {
    let resultDate = dayAPI(date);
    for (let i = 0; i < unitsToSubtract;) {
        // 根据不同的时间间隔来相减日期
        if (interval === 'hour') {
            resultDate = resultDate.subtract(1, 'hour');
        }
        else if (interval === 'week') {
            // 跳过星期六和星期日
            resultDate = resultDate.subtract(1, 'day');
            if (resultDate.day() === 0) {
                resultDate = resultDate.subtract(1, 'day');
            }
        }
        else {
            resultDate = resultDate.subtract(1, 'day');
        }
        // 如果不是星期六或星期日，才计算一个时间单位
        if (resultDate.day() !== 0 && resultDate.day() !== 6) {
            i++;
        }
    }
    return resultDate;
};
const SimpleTrendChart = memo(function SimpleTrendChart(props) {
    const recentDays = props.timeCount || 365;
    /** 後端最新交易資料 國內的交易日期 */
    const baseDate = dayAPI();
    const endOnSpecDate = props.endOnSpecDate || baseDate;
    /** 使用的單位 週、天、小時 */
    const timeUnit = props.interval || 'day';
    const range = [
        subtractBusinessUnits(baseDate, 120, timeUnit).unix(),
        baseDate.endOf('day').unix(),
    ];
    console.log(subtractBusinessUnits(baseDate, 120, timeUnit).format('YYYY/MM/DD HH:mm'));
    const { data: rawData } = useSymbolHistoryResource({
        symbol: props.symbol,
        fromTo: range,
        resolution: props.dataInterval ?? '1D',
        refreshInterval: props.refreshInterval ?? 60000,
    });
    const data = useMemo(() => {
        return rawData
            ?.map(datum => ({
            close: datum.close,
        }))
            .slice(props.count ? Math.max((rawData?.length ?? 0) - props.count - 1, 0) : 0);
    }, [rawData, props.count]);
    if (!data || (data && data.length === 0))
        return (<div css={css `
          ${fill_horizontal_all_center}
        `}>
        ...尚未載入
      </div>);
    /** 以下 ID 是處理 重複使用圖表顏色無法指定問題 */
    const splitColorID = ('splitColorSimple' + props.symbol).toString();
    const strokeColorID = ('strokeColorSimple' + props.symbol).toString();
    const fillID = 'url(#' + splitColorID + ')';
    const strokeID = 'url(#' + strokeColorID + ')';
    const start = rawData?.map(s => s.open)[0];
    const end = rawData?.map(s => s.close)[rawData.length - 1];
    const rise = (start ?? 0) <= (end ?? 0);
    const color = rise ? props.fillColorUp ?? '#dd2222' : props.fillColorDn ?? '#22bb22';
    const yRange = [
        Math.min(...(data?.map(datum => datum.close) || [])),
        Math.min(...(data?.map(datum => datum.close) || [])),
    ];
    return (<ResponsiveContainer width='100%' height='100%'>
      <AreaChart css={css `
          cursor: pointer !important;
        `} data={data} margin={{
            top: 1,
            right: 1,
            left: 1,
            bottom: 1,
        }}>
        <YAxis domain={yRange} hide={true}/>
        <XAxis hide={true} interval={10}/>

        <defs>
          <linearGradient id={splitColorID} x1='0' y1='0' x2='0' y2='1'>
            <stop offset={1 / 5} stopColor={color} stopOpacity={1}/>
            <stop offset={5 / 5} stopColor={color} stopOpacity={0}/>
          </linearGradient>
        </defs>
        <Area type='linear' dot={false} dataKey='close' stroke={color} fillOpacity={0.3} fill={fillID} isAnimationActive={false}/>
      </AreaChart>
    </ResponsiveContainer>);
});
export default SimpleTrendChart;
