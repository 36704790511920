import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody';
import { useSnapshot } from 'valtio';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { VscError } from 'react-icons/vsc';
import { tradingPlatfromStore } from './SymbolPlatformBase';
import { useThemeStore } from '~/components/theme/useThemeStore';
import SimpleTrendChart from '~/modules/investment-consultant/symbolTrendChart/SymbolTrendChart/SimpleTrendChart';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { store } from '~/pages/heineken_template/_private/store';
const QuoteBodyBaseChartHeader = memo(function QuoteBodyBaseChartHeader() {
    const theme = useThemeStore(s => s.theme);
    const iconFill = theme === 'dark' ? '#ffffff' : '#111111';
    return (<classes.Root>
      <classes.container css={css `
          width: 98%;
          border-radius: 0px;
          border-bottom: 1px solid #666666;
        `}>
        <p>
          <AiOutlineHeart size={16} fill={iconFill}/>
        </p>
        <p>商品</p>
        <p>價格</p>
        <p>走勢</p>
      </classes.container>
    </classes.Root>);
});
const QuoteBodyBaseChart = memo(function QuoteBodyBaseChart(props) {
    const theme = useThemeStore(s => s.theme);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    //是否是自選股模式
    const watchlistMode = props.watchlistMode ?? false;
    //報價類
    const quote = props.quote;
    const change = processQuoteToReadable(props.quote)?.closeChange;
    const charting = useSnapshot(store.charting);
    const currentSymbol = charting.symbol;
    //選擇中
    const isActive = quote?.symbol === currentSymbol;
    //自選
    const hadSymbol = props.watchListSymbol?.includes(quote.symbol);
    //已在自選清單內所顯示的icon
    const symbolListState = useSnapshot(tradingPlatfromStore).symbolListState;
    const selectSymbol = symbolListState === 'watchList' ? (<VscError size={16} fill={classesFill.iconRemoveFill}/>) : (<AiFillHeart size={16} fill={classesFill.iconDefaultFill}/>);
    return (<classes.Root css={baseStyled}>
      <classes.container className={theme} onClick={() => {
            store.charting.changeSymbol(quote?.symbol);
        }}>
        <classes.icon onClick={() => {
            if (hadSymbol) {
                props.watchlistActs.removeSymbol(quote?.symbol);
            }
            if (!hadSymbol) {
                props.watchlistActs.addSymbol(quote?.symbol, 20);
            }
        }}>
          {hadSymbol ? (selectSymbol) : (<AiOutlineHeart size={16} fill={classesFill.iconDefaultFill + 'aa'}/>)}
        </classes.icon>
        <p>
          {quote?.symbol}
          <SymbolName symbol={quote?.symbol}/>
        </p>
        <classes.item>
          <QuoteValueItem.ohlc.Display quote={quote} ohlcType='close'/>
        </classes.item>
        <p css={css `
            height: 100%;
            width: 100%;
            padding: 4px;
          `}>
          <SimpleTrendChart symbol={quote?.symbol} interval='hour' timeCount={5} dataInterval='5'/>
        </p>
      </classes.container>
    </classes.Root>);
});
const classesFill = {
    iconRemoveFill: '#992323',
    iconDefaultFill: '#6d9af0',
};
const classes = {
    Root: styled.div `
    ${fill_vertical_all_center};
    height: 32px;
    width: 100%;
    flex-shrink: 0;
  `,
    container: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 10% 30% 20% 40%;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.3s;
    user-select: none;
    & > p {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      margin: 0;
    }
  `,
    item: styled.p `
    display: flex;
    flex-direction: row;
    justify-content: end !important;
    margin-right: 16px !important;
    line-height: 16px;
    margin: 0;
  `,
    icon: styled.p `
    cursor: pointer;
    position: relative;

    &:hover {
      & > * {
        height: 20px;
        width: 20px;
        transition: 0.3s;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        opacity: 0;
        transition: all 0.5s;
        box-shadow: 0 0 6px 10px ${classesFill.iconDefaultFill}cc;
      }

      &:active:after {
        box-shadow: 0 0 0 0 ${classesFill.iconDefaultFill}cc;
        position: absolute;
        border-radius: 50%;
        opacity: 1;
        transition: 0s;
      }

      &:active {
        top: 1px;
      }
    }
  `,
};
export default {
    Body: QuoteBodyBaseChart,
    Header: QuoteBodyBaseChartHeader,
    classes,
    classesFill,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.container} {
    &.dark {
      color: #fafafa;
      &:hover {
        background-color: #282930;
      }
    }
    &.light {
      color: #252525;
      &:hover {
        background-color: #eaeaea;
      }
    }
  }
`;
